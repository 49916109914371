import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import PageLayout from "../components/hbnfpagelayout"
import Head from "../components/head"
import marked from "marked"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      activities {
        title
        subtitle
        note
        activities {
          activity
          details {
            details
          }
          link
          registrationUrl
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`

const Activities = props => {
  let registerUrl
  if (typeof window !== "undefined") {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  const getMarkdownText = activityDetails => {
    if (
      activityDetails &&
      (activityDetails !== null || activityDetails !== undefined)
    ) {
      var rawMarkup = marked(activityDetails, { sanitize: true, breaks: true })
      return { __html: rawMarkup }
    }
  }

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.activities?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.activities?.seoMetadata?.description
            ?.description
        }
      />
      <div className="activites_body">
        {props?.data?.contentfulEvents?.activities?.activities === null && (
          <div className="activities_note">
            {props?.data?.contentfulEvents?.activities?.note}
          </div>
        )}
        {props?.data?.contentfulEvents?.activities?.activities !== null && (
          <>
            <div className="activites_row">
              <h1
                style={{
                  background: `-webkit-linear-gradient(top left, ${props?.data?.contentfulEvents?.headerColor})`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {props?.data?.contentfulEvents?.activities?.title}
              </h1>
              <p>{props?.data?.contentfulEvents?.activities?.subtitle}</p>
            </div>
            {props?.data?.contentfulEvents?.activities?.activities?.map(
              (item, index) => {
                return (
                  <div
                    className="activites_row"
                    key={index}
                    style={{ marginBottom: "40px" }}
                  >
                    <h5>
                      {item?.activity}{" "}
                      {item.registrationUrl && (
                        <a
                          href={item.registrationUrl}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          (Register)
                        </a>
                      )}
                    </h5>

                    <div
                      className="activites_row_p"
                      dangerouslySetInnerHTML={getMarkdownText(
                        item?.details?.details
                      )}
                    ></div>
                    {item?.link && <Link to={item?.link}>Learn More</Link>}
                  </div>
                )
              }
            )}
          </>
        )}
      </div>
    </PageLayout>
  )
}
export default Activities
